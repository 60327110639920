export default {
    name: "aboutUs",
    data() {
        return {
            banner: 'http://public.wencaishui.com/images/learning/pc/about-2.jpeg',

            intro: [
                {
                    eng: 'PROFESSIONAL AND EXCELLENT',
                    title: '因为专业所以优秀',
                    desc: '我们专注于财税管理与服务领域，面向广大的财务税务工作者，实施有计划、系统的、可管理的、针对性的培养与训练活动。目标在于帮助他们在财税知识、技能、工具等方便得到持续改善与提高，能够从容的面对财税政策持续变化、实务技能需要不断更新的挑战，进而推动组织整体能力的不断进步。 ',
                    desc2: '学税通以在线网络远程授课的方式为主，结合监督式的训练平台与工具，更低的拥有成本，更便捷的学习方式。'
                },{
                    eng: 'COMPANY CULTURE',
                    title: '企业文化',
                    desc: '客户至上：我们秉承客户至上的商业道德，把不断满足客户需求，提升客户体验、超越客户期望作为我们前进的动力。<br/>' +
                          '持续创新：不断的服务创新是我们成长的助推器，我们期望在服务的内容、形式与体验上能够持续创造惊喜。<br/>' +
                          '诚信负责：坚守契约精神，倡导诚信负责，是我们坚守的底线；我们拒绝任何形式与任何理由的无故违约与推诿。<br/>' +
                          '合作共赢：我们坚信，与我们的客户、我们的合伙伙伴之间保持更加紧密的合作关系，才能获得共同的发展与进步。',
                }
            ],

            statistics: [
                {
                    title: '财税实务课程库',
                    content: '2000',
                    suff: '+'
                },{
                    title: '服务过的财税工作者',
                    content: '10',
                    suff: '万+'
                },{
                    title: '解决财务实战问题',
                    content: '5',
                    suff: '万+'
                },{
                    title: '专业财税服务',
                    content: '12',
                    suff: '年+'
                },
            ],

            banners: [
                'http://public.wencaishui.com/images/learning/pc/about-banner1.jpeg',
                'http://public.wencaishui.com/images/learning/pc/about-banner2.jpeg'
            ],
            imgs: [
                '//24181030.s61i.faiusr.com/2/AD0IpvLDCxACGAAgusr49gUo2J2p5QMw0AU4xQM.jpg.webp'
            ]
        }
    }
}
